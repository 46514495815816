@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-primary text-lg;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-dark;
  }
  .title,
  .section-title {
    @apply text-4xl lg:text-6xl mb-6 font-bold;
  }
  .h2 {
    @apply text-3xl lg:text-5xl font-bold;
  }
  .pretitle {
    @apply text-lg lg:text-[22px] text-light mb-4 lg:mb-6 uppercase font-normal;
  }
  .lead {
    @apply text-xl text-dark/55 font-normal mb-6;
  }
  .link {
    @apply text-lg text-dark;
  }
  .section {
    @apply py-8 lg:py-[40px];
  }
  .btn {
    @apply rounded-lg transition flex justify-center items-center font-secondary font-semibold;
  }
  .btn-lg {
    @apply h-[60px] px-6 text-xl font-semibold;
  }
  .btn-md {
    @apply h-[50px] px-[14px] text-lg font-semibold;
  }
  .btn-sm {
    @apply h-[40px] px-[12px] text-lg;
  }
  .btn-accent {
    @apply text-white bg-accent hover:bg-accentHover;
  }
  .btn-white {
    @apply text-accent bg-white hover:bg-accentHover hover:text-white;
  }
  .btn-outline {
    @apply bg-transparent text-accent border border-accent hover:bg-accent hover:text-white;
  }
  .btn-link {
    @apply text-accent text-xl lg:text-2xl;
  }
}